import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout';
import { Blades } from '../common/blades/Blades';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { GlobalStrings } from '../../common/fragments/MRKConfig';
import { ContentfulPage } from '../../contentful/content-types/page/page.interface';
import { useCMSState } from '@internal/useCMSHook';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!) {
    page(relativePath: { eq: "securityspotlight" }, locale: { eq: $locale }) {
      ...securitySpotlightFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }
  }
`;
export interface SecuirtyPageParams extends GatsbyTemplateProps<AppContextProps> {
  data: {
    page: ContentfulPage;
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
    globalStrings: GlobalStrings;
  };
}

const SecurityPage: FC<SecuirtyPageParams> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { page, headerData, footerData, globalStrings } = data;
  if (config.targetEnv === 'staging') {
    page = useCMSState<ContentfulPage>(page);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    footerData = useCMSState<MainFooter>(footerData);
    globalStrings = useCMSState<GlobalStrings>(globalStrings);
  }

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>Cloudflare</title>
      </Helmet>
      {page.relatedBlades && (
        <Blades blades={page.relatedBlades} globalStrings={globalStrings}></Blades>
      )}
    </Layout>
  );
};

export default SecurityPage;
